import React from 'react';
import { PostAdd, People, Chat, Euro, PlaceOutlined, Star, CardGiftcard } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';
import {
  MdCalendarToday,
  MdOutlineProductionQuantityLimits,
  MdEqualizer,
  MdOutlineEqualizer,
  MdOutlineGroupAdd,
  MdListAlt,
  MdWork,
  MdLocalPolice,
  MdOutlineLocalPolice,
  MdEngineering,
  MdEventAvailable,
  MdOutlineBorderColor,
  MdOutlinePendingActions,
  MdContentPaste,
} from 'react-icons/md';

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'sidebar.calendar'} />,
        type: 'item',
        icon: <MdCalendarToday />,
        link: '/calendario',
      },
      {
        name: <IntlMessages id={'sidebar.dashboard.crm'} />,
        type: 'item',
        icon: <MdEqualizer />,
        link: '/dashboard/crm',
      },

      {
        name: <IntlMessages id={'sidebar.modules.customers'} />,
        icon: <People />,
        type: 'item',
        link: '/customers',
      },
      {
        name: <IntlMessages id={'sidebar.modules.users'} />,
        icon: <MdOutlineGroupAdd />,
        type: 'item',
        link: '/users',
      },
      {
        name: <IntlMessages id={'sidebar.modules.fornitori'} />,
        icon: <MdOutlineProductionQuantityLimits />,
        type: 'item',
        link: '/fornitori',
      },
      {
        name: <IntlMessages id={'sidebar.modules.partiteAperte'} />,
        icon: <Euro />,
        type: 'item',
        link: '/partite-aperte',
      },
      {
        name: 'Prodotti',
        icon: <MdListAlt />,
        type: 'collapse',
        children: [
          {
            name: <IntlMessages id={'sidebar.modules.offerte'} />,
            icon: <MdOutlineBorderColor />,
            type: 'item',
            link: '/offerte',
          },
          {
            name: <IntlMessages id={'sidebar.modules.offerte-cerca'} />,
            icon: <MdOutlinePendingActions />,
            type: 'item',
            link: '/offerte-cerca',
          },
          {
            name: <IntlMessages id={'sidebar.modules.ordini'} />,
            icon: <MdContentPaste />,
            type: 'item',
            link: '/ordini',
          },
          {
            name: <IntlMessages id={'sidebar.modules.ordini-fornitori'} />,
            icon: <MdOutlinePendingActions />,
            type: 'item',
            link: '/ordini-fornitori',
          },
        ],
      },
      {
        name: 'Interventi e manutenzioni',
        icon: <MdWork />,
        type: 'collapse',
        children: [
          {
            name: 'Assistenza tecnica',
            icon: <MdEngineering />,
            type: 'item',
            link: '/offerte-interventi',
          },
          /*
          {
            name: <IntlMessages id={'sidebar.modules.ordini-fornitori'} />,
            icon: <MdEventAvailable />,
            type: 'item',
            link: '/ordini-fornitori-interventi',
          },
          */
          {
            name: 'Istituti di vigilanza',
            icon: <MdLocalPolice />,
            type: 'item',
            link: '/istituti-di-vigilanza',
          },
          /*
          {
            name: <IntlMessages id={'sidebar.modules.ordini'} />,
            icon: <PlaceOutlined />,
            type: 'item',
            link: '/ordini-interventi',
          },
          */
        ],
      },
      /*
      {
        name: <IntlMessages id={'sidebar.appModule.kanbanBoard'} />,
        type: 'item',
        icon: <Chat />,
        link: '/apps/kanban-board',
      },
      {
        name: <IntlMessages id={'Ricerca cliente'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/ricerca-cliente',
      },
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
      */
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
